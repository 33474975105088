import React from 'react';
import { actCb2LPopUp, actHideDepth1LPopUp } from '../../../utile/slice/layerPopUpSlice';
import { useAppDispatch } from '../../../store';

function TermsPop() {
  const dispatch = useAppDispatch();

  const closeLayerPopUp = () => {
    dispatch(actHideDepth1LPopUp());
    dispatch(actCb2LPopUp('unChecked'));
  };

  const confirmAgree = () => {
    dispatch(actHideDepth1LPopUp());
    dispatch(actCb2LPopUp('checked'));
  };

  return (
    <div className="popup_wrap">
      <div className="dim"></div>
      <div className="pop_l_inner">
        <div className="pop_header">
          <strong
            className="tlt"
            style={{ display: 'block', margin: '20px 0 0 0', fontSize: '28px' }}
          >
            개인정보 제3자 제공 동의
          </strong>
          <button
            type="button"
            title="팝업 닫기"
            className="ico_22_pop_close"
            onClick={closeLayerPopUp}
          ></button>
        </div>
        <div className="pop_cont replace_page">
          <div className="privacy">
            <ol>
              <li>
                <b style={{ fontWeight: 'bold' }}>
                  ㈜GS글로벌(이하 ‘회사’라 한다)은 정보주체의 개인정보를 제1조(개인정보의 처리
                  목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
                  개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
                </b>
              </li>
              <li style={{ marginTop: '32px' }}>
                <b style={{ fontWeight: 'bold' }}>
                  회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                </b>
                <div className="table_wrap">
                  <div className="table_scroll_wrap">
                    <table>
                      <caption className="blind">친환경차 취득세 감면 안내표</caption>
                      <thead>
                        <tr>
                          <th>제공받는 자</th>
                          <th>제공 목적</th>
                          <th>개인정보 항목</th>
                          <th>보유 및 이용 기간</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>G&B CV (Commercial Vehicle), 자일자동차, GS mbiz</td>
                          <td>보증수리 및 차량관리 서비스 제공</td>
                          <td>
                            성명, 주소, 연락처(집 전화번호/ 휴대폰번호), 이메일, 구입 차량정보
                          </td>
                          <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                        </tr>
                        <tr>
                          <td rowspan="2">G&B CV(Commercial Vehicle), 신아주e트럭, 위시모터스</td>
                          <td>전기자동차 사전 예약 진행</td>
                          <td>성명, 연락처(집 전화번호/핸드폰 번호), 거주하는 지역 </td>
                          <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                        </tr>
                        <tr>
                          {/* <td></td> */}
                          <td>전기자동차 시승 및 상담 진행</td>
                          <td>
                            성명, 연락처(집 전화번호/핸드폰 번호), 거주하는 지역, 관심 차종, 방문
                            일시
                          </td>
                          <td>시승 및 상담 신청일로부터 1년 혹은 동의 철회 시까지</td>
                        </tr>
                        <tr>
                          <td>BYD (중국) [+86-755-89888888-57137]</td>
                          <td>차량 Warranty 서비스 제공</td>
                          <td>
                            차량번호, 차대번호, A/S 관련 이력
                            <br />* 이전일시 및 방법: 서비스 요청 시 연결 프로그램을 통해 이전
                          </td>
                          <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                        </tr>
                        <tr>
                          <td>신한카드</td>
                          <td>차량 할부 금융서비스 제공</td>
                          <td>
                            성명, 주소, 연락처(집 전화번호/ 휴대폰번호), 이메일, 구입 차량정보
                          </td>
                          <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </li>
              <li style={{ marginTop: '32px' }}>
                <b style={{ fontWeight: 'bold' }}>
                  회사는 개인정보 보호법 제17조 제1항 제1호에 따라 법령상 의무 준수 등을 위하여
                  정보주체의 동의 없이 관계 기관에 개인정보를 제공할 수 있습니다. 또한, 본 항에
                  명시되지 않은 사항에 대해서도 법률의 특별한 규정이 있거나 법령상 의무 준수를 위한
                  경우에는 정보주체의 동의 없이 개인정보를 제공할 수 있습니다.
                </b>
                <div className="table_wrap">
                  <div className="table_scroll_wrap">
                    <table>
                      <caption className="blind">친환경차 취득세 감면 안내표</caption>
                      <thead>
                        <tr>
                          <th>제공 기관</th>
                          <th>제공 사유</th>
                          <th>제공 항목</th>
                          <th>근거 법령</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>환경부 및 소속기관</td>
                          <td>리콜통지문 발송 (차량 결함 확인시)</td>
                          <td>차대번호</td>
                          <td>
                            대기환경보전법 제51조, 같은 법 시행규칙 제75조 제6호, 자동차관리법
                            제31조 제1항, 같은 법 시행규칙 제41조 제2항 제7호, 자동차 및
                            자동차부품의 인증 및 조사 등에 관한 규정 제8조의2
                          </td>
                        </tr>
                        <tr>
                          <td>국토교통부 및 관련기관</td>
                          <td>국토교통부 및 관련기관</td>
                          <td>이름, 주소, 주민등록번호, 차종·형식, 차대번호</td>
                          <td>자동차관리법 제8조 제3항, 자동차등록규칙 제4조 제1항</td>
                        </tr>
                        <tr>
                          <td>국세청</td>
                          <td>부가가치세 등 각종 세금의 신고</td>
                          <td>이름, 주소, 연락처, 주민등록번호</td>
                          <td>부가가치세법 제32조 제1항, 같은 법 시행령 제67조 제4항</td>
                        </tr>
                        <tr>
                          <td>한국소비자원 및 위탁단체(소비자단체)</td>
                          <td>소비자 상담 및 구제 </td>
                          <td>이름, 주소, 연락처, 차량정보, 차량정비이력</td>
                          <td>소비자기본법 제18조 제2항, 제78조 제1항</td>
                        </tr>
                        <tr>
                          <td>신한카드</td>
                          <td>차량 할부 금융서비스 제공</td>
                          <td>
                            성명, 주소, 연락처(집 전화번호/ 휴대폰번호), 이메일, 구입 차량정보
                          </td>
                          <td>법령상 의무 보존 기간이 없는 한 관련 목적 달성 시까지</td>
                        </tr>
                        <tr>
                          <td>지방자치단체</td>
                          <td>보조금 지원</td>
                          <td>자동차등록증 작성을 위한 정보 일체</td>
                          <td>
                            보조금 관리에 관한 법률 제26조의3 제1항 제8호, 같은 법 시행령 제10조의5
                            제1항
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>소비자 상담 및 구제</td>
                          <td>이름, 주소, 연락처, 차량정보, 차량정비이력</td>
                          <td>소비자기본법 제18조 제4항</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </li>
            </ol>
          </div>
        </div>
        <div className="pop_footer">
          <div className="btn_layout">
            <button type="button" className="btn_primary_m" onClick={confirmAgree}>
              <span>동의하기</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TermsPop);
